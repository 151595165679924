<template>
	<div class="unit-image">
		<div class="no-unit" v-if="img === null">
			<img src="@/assets/images/no-unit.svg" alt="Unit is not selected" />
		</div>
		<div class="unit-image-inner" v-else>
			<img :src="img" />
		</div>
	</div>
</template>

<script>
import {computed} from "vue";

export default {
	props: {
		unit: {type: Object}
	},
	setup(props) {

		const img = computed(() => {
			console.log(props.unit);

			if (props.unit != null) {
				return props.unit.img;
			}

			return null;
		})

		return {
			img
		}
	}
}
</script>

<style lang="scss" scoped>
.unit-image {
	width: 100%;
	min-height: 400px;
	margin-right: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	border: 1px solid #dddddd;

	.no-unit {
		width: 200px;
		height: 200px;
		opacity: 0.4;
	}

	.unit-image-inner {
		width: 100%;

		img {
			width: 100%;
		}
	}
}
</style>
