<template>
	<div class="chat">
		<h1>Chat</h1>

		<div class="chat-messages">
			<div class="message"><span class="user-id">#24:</span><span class="msg">hello</span></div>
			<div class="message my"><span class="user-id">#100:</span><span class="msg">hi</span></div>
		</div>

		<div class="send-message">
			<input type="text" class="form-control" /> <button>Send</button>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.chat {
	border: 1px solid #dddddd;
	margin: 0 50px 30px 50px;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
	padding: 15px;

	h1 {
		margin: 0 0 15px 0;
		color: #848586;
	}

	.chat-messages {
		border: 1px solid rgba(0, 79, 45, 0.2);
		//background-color: #EDFFEC;
		padding: 15px;
		margin-bottom: 10px;

		.message {
			margin-bottom: 5px;
			display: flex;
			background-color: #004F2D;
			color: white;
			padding: 5px 0;
			border-radius: 5px;
			//justify-content: space-between;

			&.my {
				background-color: white;
				color: #004F2D;
			}

			&:last-child {
				margin-bottom: 0;
			}

			.user-id {
				width: 80px;
				text-align: right;
				padding-right: 15px;
			}
		}
	}

	.send-message {
		display: flex;
		justify-content: stretch;

		input {
			width: 100%;
			border: 1px solid #004F2D;
			color: #004F2D;
			border-radius: 5px;
			padding: 15px;
			margin-right: 15px;
		}

		button {
			border-radius: 5px;
			border: 1px solid #004F2D;
			color: #004F2D;
			background-color: rgba(0, 79, 45, 0.1);
			text-transform: uppercase;
			font-weight: bold;
			font-size: 18px;
			padding: 0 30px;
			transition: all 300ms;

			&:hover {
				background-color: rgba(0, 79, 45, 0.2);
				cursor: pointer;
			}
		}
	}
}
</style>
