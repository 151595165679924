<template>
<div class="player-inner">
	<h1>Opponent</h1>
	<div class="panel">
		<UnitImage></UnitImage>

		<UnitPanel style="display: none;"></UnitPanel>
		<PlayerMessage msg="Waiting for game start"></PlayerMessage>
	</div>
</div>
</template>

<script>
import {ref} from "vue";
import UnitPanel from "@/components/UnitPanel.vue";
import PlayerMessage from "@/components/PlayerMessage.vue";
import UnitImage from "@/components/UnitImage.vue";

export default {
	components: {UnitImage, PlayerMessage, UnitPanel},
	setup() {
		const unit = ref(null);

		return {
			unit
		}
	}
}
</script>

<style lang="scss" scoped>
.player-inner {
	padding: 15px;
	//border: 1px solid green;
	width: 100%;

	h1 {
		margin: 0 0 15px 0;
		padding: 0;
		color: #848586;
	}

	.panel {
		display: flex;
		justify-content: space-between;
		//align-items: stretch;
		align-content: stretch;


	}
}
</style>
