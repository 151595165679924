<template>
	<div class="wallet-connect">
		<p>Please connect your wallet to<br />select SOLTank and start game</p>
		<div class="wallets">
			<button class="phantom-wallet" v-if="walletsInstalled.phantom === true" @click="connectWallet('phantom')"><img src="@/assets/images/wallets/phantom.svg" /></button>
		</div>
		<div class="install-wallet" v-if="installWallet">
			Please install Phantom wallet from this link: <a href="https://phantom.app/" target="_blank">https://phantom.app/</a>
		</div>
	</div>
</template>

<script>
import {onBeforeMount, ref} from "vue";

export default {
	emits: ['walletConnected'],
	setup(props, ctx) {
		const walletsInstalled = ref({
			'phantom': false
		});
		const installWallet = ref(false);
		const walletPubKey = ref(null);
		const walletActive = ref(null);

		onBeforeMount(() => {
			window.addEventListener('load', initWallets);
		});

		const initWallets = () => {
			if ('solana' in window) {
				walletsInstalled.value.phantom = window.solana && window.solana.isPhantom;

				if (walletsInstalled.value.phantom === true) {
					installWallet.value = false;
				}

				if (walletsInstalled.value.phantom === true) {
					window.solana.on("connect", onPhantomConnect);

					// try to connect automatically
					window.solana.connect({onlyIfTrusted: true})
				}
			}
		};

		const connectWallet = async (wallet) => {
			if (wallet === 'phantom') {
				try {
					await window.solana.connect();
				} catch (err) {
					alert(err.message);
					// { code: 4001, message: 'User rejected the request.' }
				}
			}
		}

		const onPhantomConnect = () => {
			walletPubKey.value = window.solana.publicKey.toString();
			walletActive.value = 'phantom';
			window.localStorage.setItem('walletPublicKey', walletPubKey.value);
			window.localStorage.setItem('walletName', walletActive.value);

			// emit walletConnected
			ctx.emit('walletConnected');
		}

		return {
			walletsInstalled,
			installWallet,

			connectWallet
		}
	}
}
</script>

<style lang="scss" scoped>
.wallet-connect {
	width: 100%;
	display: flex;
	flex-flow: column;
	text-align: center;

	p {
		width: 100%;
		text-align: center;
		color: #848586;
		transition: all 1000ms;
		line-height: 1.5rem;
	}

	.wallets {
		button {
			border: 0 none;
			border-radius: 5px;
			padding: 5px 10px;
			transition: all 200ms;

			&:hover {
				cursor: pointer;
			}

			&.phantom-wallet {
				background-color: #2C2D30;

				&:hover {
					background-color: #505356;
				}
			}
		}
	}
}
</style>
