<template>
<div class="player-message">
	<p ref="msgNode">{{ msg }}</p>
</div>
</template>

<script>
import {onMounted, onUnmounted, ref} from "vue";

export default {
	props: {
		msg: {type: String}
	},
	setup() {
		let tmr = null;
		const msgNode = ref(null);

		onMounted(() => {
			tmr = setInterval(() => {
				if (msgNode.value.style.opacity === "1") {
					msgNode.value.style.opacity = 0.6;
				} else {
					msgNode.value.style.opacity = 1;
				}
			}, 500);
		})

		onUnmounted(() => {
			clearInterval(tmr);
		})

		return {
			msgNode
		}
	}
}
</script>

<style lang="scss" scoped>
.player-message {
	width: 100%;

	display: flex;
	align-items: center;
	text-align: center;

	p {
		width: 100%;
		text-align: center;
		color: #848586;
		transition: all 1000ms;
	}
}
</style>
