<template>
	<GameScreen></GameScreen>
	<ChatScreen></ChatScreen>
</template>

<script>
	import GameScreen from "@/components/GameScreen.vue";
	import ChatScreen from "@/components/ChatScreen.vue";

	export default {
		name: 'App',
		components: {
			GameScreen,
			ChatScreen
		}
	}
</script>

<style>
@import "./assets/fontawesome/css/all.min.css";
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;400;500;600&display=swap');

* {
	font-family: "Exo 2", Arial, sans-serif;
}
</style>
