<template>
<div class="player-inner">
	<h1>Me</h1>
	<div class="panel">
		<UnitImage :unit="unit"></UnitImage>

		<WalletConnect v-if="gameStatus === 'select-wallet'" @walletConnected="changeStatus('select-unit')"></WalletConnect>
		<WalletNFTChoice v-else-if="gameStatus === 'select-unit'" @unitChanged="changeUnit($event)" @unitSelected="setUnit($event)"></WalletNFTChoice>
		<UnitPanel v-else-if="gameStatus === 'game'" :turn="turn" @submitTurn="submitTurn"></UnitPanel>
	</div>
</div>
</template>

<script>
import {ref} from "vue";
import UnitPanel from "@/components/UnitPanel.vue";
import UnitImage from "@/components/UnitImage.vue";
import WalletConnect from "@/components/WalletConnect.vue";
import WalletNFTChoice from "@/components/WalletNFTChoice.vue";

export default {
	components: {WalletNFTChoice, WalletConnect, UnitImage, UnitPanel},
	setup() {
		const unit = ref(null);
		const gameStatus = ref(null);
		const turn = ref(1);

		// check if wallet is connected
		if (window.localStorage.getItem('walletPublicKey') == null) {
			gameStatus.value = 'select-wallet';
		} else if (window.localStorage.getItem('unit') == null) {
			gameStatus.value = 'select-unit';
		} else {
			unit.value = JSON.parse(window.localStorage.getItem('unit'));
			gameStatus.value = 'game';
		}

		const changeStatus = (newStatus) => {
			gameStatus.value = newStatus
		}

		const changeUnit = (u) => {
			unit.value = u;
		}

		const setUnit = (u) => {
			console.log(u);
			gameStatus.value = 'game';
			unit.value = u;
		}

		const submitTurn = () => {
			turn.value++;
		}

		return {
			unit,
			gameStatus,
			changeStatus,
			changeUnit,
			setUnit,
			submitTurn,

			turn,
		}
	}
}
</script>

<style lang="scss" scoped>
	.player-inner {
		padding: 15px;
		//border: 1px solid green;
		width: 100%;

		h1 {
			margin: 0 0 15px 0;
			padding: 0;
			color: #848586;
		}

		.panel {
			display: flex;
			justify-content: space-between;
			//align-items: stretch;
			align-content: stretch;
		}
	}
</style>
