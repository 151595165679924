<template>
	<div class="actions">
		<div class="unit-stat">
			<span>Health:</span>
			<div class="stat-holder">
				<div class="hp" :style="{width: hpWidth + '%', backgroundColor: hpBg, color: hpFg}">{{ hp }}%</div>
			</div>
		</div>

		<div class="status">
			<h6>Turn {{ turn }}</h6>
		</div>

		<div class="gameplay">
			<div class="controls">
				<div class="attack">
					<h5>Attack</h5>
					<div class="action-buttons">
						<button @click="attack = 'turret'" :class="{'selected': attack === 'turret'}">Turret</button>
						<button @click="attack = 'body'" :class="{'selected': attack === 'body'}">Body</button>
						<button @click="attack = 'caterpillar'" :class="{'selected': attack === 'caterpillar'}">Caterpillar</button>
					</div>
				</div>

				<div class="defense">
					<h5>Defense</h5>
					<div class="action-buttons">
						<button @click="defense = 'turret'" :class="{'selected': defense === 'turret'}">Turret</button>
						<button @click="defense = 'body'" :class="{'selected': defense === 'body'}">Body</button>
						<button @click="defense = 'caterpillar'" :class="{'selected': defense === 'caterpillar'}">Caterpillar</button>
					</div>
				</div>
			</div>

			<div class="bottom-controls">
				<button :disabled="attack === '' || defense === ''" @click="submitTurn">Submit</button>
			</div>
		</div>
	</div>
</template>

<script>
import {computed, ref} from "vue";

export default {
	props: {
		turn: {}
	},
	emits: ['submitTurn'],
	setup(props, ctx) {
		const hp = ref(100);
		const attack = ref('');
		const defense = ref('');

		const hpWidth = computed(() => {
			let w = hp.value;
			w *= 0.8;
			w += 20;

			return w.toFixed(0);
		});

		const hpBg = computed(() => {
			return 'rgba(255, 0, 0, ' + (hp.value / 100).toFixed(3) + ')';
		})

		const hpFg = computed(() => {
			let hpV = hp.value;
			if (hpV > 30) {
				hpV = 100;
			}
			let r = 255 * hpV / 100;
			r = r.toFixed(0);

			return 'rgba(255, ' + r + ', ' + r + ', 1)';
		})

		const submitTurn = () => {
			attack.value = '';
			defense.value = '';

			ctx.emit('submitTurn');
		}

		return {
			hp,
			hpWidth,
			hpBg,
			hpFg,
			attack,
			defense,

			submitTurn
		}
	}
}
</script>

<style lang="scss" scoped>
	//@import "./src/styles/consts";

	.actions {
		width: 100%;
		display: flex;
		flex-flow: column;
		justify-content: space-between;

		.unit-stat {
			margin-bottom: 10px;
			display: flex;
			align-items: center;

			span {
				margin-right: 5px;
			}

			.stat-holder {
				width: 100%;

				.hp {
					width: 100%;
					background-color: red;
					color: white;
					padding: 10px 0;
					text-align: center;
					transition: all 100ms;
					border: 1px solid red;
					border-radius: 5px;
				}
			}
		}

		.status {
			text-align: center;
			border-bottom: 1px solid #38023B;
			padding: 0 0 5px 0;

			h6 {
				padding: 5px;
				margin: 0 0 5px 0;
				background-color: #38023B;
				color: white;
				font-size: 18px;
			}
		}

		.gameplay {
			display: flex;
			flex-flow: column;

			.controls {
				text-align: center;
				display: flex;
				justify-content: space-between;
				margin-bottom: 15px;

				.action-buttons {
					display: flex;
					justify-content: space-between;
					flex-flow: column;

					button {
						width: 100%;
						margin: 5px 0;
						padding: 15px;
						color: #004F2D;
						text-transform: uppercase;
						border: 1px solid #004F2D;
						transition: all 300ms;
						border-radius: 5px;

						&:first-child {
							margin-left: 0;
						}

						&:last-child {
							margin-right: 0;
						}

						&.selected {
							font-weight: bold;
							background-color: #004F2D;
							color: white;
						}

						&:hover {
							background-color: rgba(0, 79, 45, 0.2);
							cursor: pointer;
						}
					}
				}
			}

			.bottom-controls {
				text-align: center;

				button {
					border: 0 none;
					background-color: #004F2D;
					color: white;
					text-transform: uppercase;
					padding: 15px 30px;

					&:hover {
						cursor: pointer;
					}

					&:disabled {
						color: white;
						background-color: #848586;

						&:hover {
							cursor: default;
						}
					}
				}
			}
		}
	}
</style>
