<template>
	<div class="container-fluid">
		<div class="game-screen">
			<div class="player-screen">
				<PlayerScreen></PlayerScreen>
			</div>

			<div class="player-separator"></div>

			<div class="player-screen">
				<OpponentScreen></OpponentScreen>
			</div>
		</div>
	</div>
</template>

<script>
import PlayerScreen from "@/components/PlayerScreen.vue";
import OpponentScreen from "@/components/OpponentScreen.vue";

export default {
	name: 'GameScreen',
	components: {
		OpponentScreen,
		PlayerScreen
	}
}
</script>

<style lang="scss" scoped>
.game-screen {
	display: flex;
	justify-content: space-between;
	height: 500px;
	padding: 50px 50px 30px 50px;

	.player-separator {
		width: 100px;
	}

	.player-screen {
		display: flex;
		//justify-content: stretch;
		//align-content: stretch;
		width: 100%;

		margin: 0;
		padding: 0;
		border: 1px solid #dddddd;
		box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
	}
}
</style>
