<template>
	<div class="wallet-nfts">
		<p>Select NFT you want to play with</p>
		<div class="units">
			<div class="unit" :class="{'selected': nft === n}" v-for="n in nfts" :key="n.name" @click="setUnit(n)">
				<img :src="n.img" :title="n.name" />
				<div class="unit-title">
					<span>{{ n.name }}</span>
				</div>
			</div>
		</div>

		<div class="controls">
			<button :disabled="nft === null" @click="confirmUnit">Start Game</button>
		</div>
	</div>
</template>

<script>
import {onMounted, ref} from "vue";
import {createConnectionConfig, getParsedNftAccountsByOwner, isValidSolanaAddress} from "@nfteyez/sol-rayz";
import {clusterApiUrl} from "@solana/web3.js";
import axios from "axios";

export default {
	emits: ['unitSelected', 'unitChanged'],
	setup(props, ctx) {
		const nfts = ref([]);
		const walletPublicKey = ref(window.localStorage.getItem('walletPublicKey'));
		const nft = ref(null);

		onMounted(async () => {
			try {
				const connect = createConnectionConfig(clusterApiUrl("mainnet-beta"));
				const ownerToken = walletPublicKey.value;
				const isPublicKeyValid = isValidSolanaAddress(ownerToken);

				if (isPublicKeyValid === true) {
					const nftsRaw = await getParsedNftAccountsByOwner({
						publicAddress: ownerToken,
						connection: connect,
						serialization: true,
					});

					try {
						const data = Object.keys(nftsRaw).map((key) => nftsRaw[key]);
						let n = data.length;
						for (let i = 0; i < n; i++) {
							// console.log(data[i].data.uri);
							const val = await axios.get(data[i].data.uri);
							const valData = val.data;
							// console.log(data[i]);
							// console.log(val.data);

							nfts.value.push({
								name: valData.name,
								img: valData.image,
								description: valData.description,
								mint: data[i].mint,
							});
						}
					} catch (error) {
						console.log(error);
					}
				}

			} catch (err) {
				console.log(err);
			}
		})

		const setUnit = (unit) => {
			nft.value = unit;
			ctx.emit('unitChanged', nft.value);
		}

		const confirmUnit = () => {
			window.localStorage.setItem('unit', JSON.stringify(nft.value))
			ctx.emit('unitSelected', nft.value);
		}

		return {
			nfts,
			nft,

			setUnit,
			confirmUnit
		}
	}
}
</script>

<style lang="scss" scoped>

.wallet-nfts {
	width: 100%;
	display: flex;
	flex-flow: column;
	text-align: center;

	.units {
		display: flex;
		flex-wrap: wrap;
		overflow: auto;
		padding: 5px;
		height: 280px;
		justify-content: center;
		margin-bottom: 15px;

		.unit {
			width: 30%;
			border: 1px solid #dddddd;
			margin: 5px;
			display: flex;
			flex-flow: column;
			align-items: stretch;
			transition: all 200ms;

			&:hover {
				cursor: pointer;
				background-color: #dddddd;
			}

			&.selected {
				background-color: #004F2D;

				.unit-title {
					color: white;
				}
			}

			img {
				width: 100%;
			}

			.unit-title {
				margin: 0;
				padding: 0 3px 3px 3px;
				font-size: 11px;
				color: #848586;
				display: flex;
				min-height: 52px;
				align-items: center;
				justify-content: center;

				span {
					display: block;
				}
			}
		}
	}

	.controls {
		margin-right: 16px;

		button {
			border: 0 none;
			background-color: #004F2D;
			color: white;
			text-transform: uppercase;
			padding: 15px 30px;

			&:hover {
				cursor: pointer;
			}

			&:disabled {
				color: white;
				background-color: #848586;

				&:hover {
					cursor: default;
				}
			}
		}
	}
}
</style>
